import React, { useRef, useEffect, useState } from "react";
import headerFull from "../assets/images/headerFull.png";
import headerSideSliver from "../assets/images/headerSideSliver.png";
import lootIcon from "../assets/images/loot.png";
import eloIcon from "../assets/images/ELO.png";
import { formatNumberWithCommas } from "../utils/Utils";
import "../styles/Header.css";

function Header({
  playerName,
  level,
  maxLevel,
  lootAmount,
  bountyBoost,
  elo,
  onTitleSecretTap,
  onClick,
}) {
  const levelInfoRef = useRef(null);
  const bountyBoostRef = useRef(null);
  const [playerNameMaxWidth, setPlayerNameMaxWidth] = useState("auto");

  const calculateFontSize = (name) => {
    const length = name.length;
    if (length > 10) return 13;
    if (length > 9) return 14;
    if (length > 8) return 15;
    return 16;
  };

  useEffect(() => {
    const updatePlayerNameMaxWidth = () => {
      if (levelInfoRef.current && bountyBoostRef.current) {
        const levelInfoWidth = levelInfoRef.current.offsetWidth;
        const bountyBoostWidth = bountyBoostRef.current.offsetWidth;
        const headerContentPadding = 16;
        const additionalPadding = 52;
        const newMaxWidth = `calc(100vw - ${
          levelInfoWidth +
          bountyBoostWidth +
          headerContentPadding +
          additionalPadding
        }px)`;
        setPlayerNameMaxWidth(newMaxWidth);
      }
    };

    updatePlayerNameMaxWidth();
    window.addEventListener("resize", updatePlayerNameMaxWidth);

    return () => {
      window.removeEventListener("resize", updatePlayerNameMaxWidth);
    };
  }, []);

  return (
    <div className="header">
      <img src={headerSideSliver} alt="" className="header-side left" />
      <img src={headerFull} alt="" className="header-full" />
      <img src={headerSideSliver} alt="" className="header-side right" />
      <div className="header-content" onClick={onClick}>
        <div className="elo-info" ref={levelInfoRef}>
          <span className="header-stat-label">ELO</span>
          <div className="header-stat-value">
            <img src={eloIcon} alt="Loot" className="header-stat-icon" />
            <span className="header-stat-label">{elo}</span>
          </div>
        </div>
        <div
          onClick={onTitleSecretTap}
          className="player-name"
          style={{
            fontSize: calculateFontSize(playerName),
            maxWidth: playerNameMaxWidth,
          }}
        >
          {playerName.toUpperCase()}
        </div>
        <div className="loot-amount">{formatNumberWithCommas(lootAmount)}</div>
        <div className="bounty-boost" ref={bountyBoostRef}>
          <span className="header-stat-label">Bounty Boost</span>
          <div className="header-stat-value">
            <img src={lootIcon} alt="Loot" className="header-stat-icon" />
            <span className="header-stat-label-bounty-boost">
              x {bountyBoost.toFixed(2)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
