import React from "react";
import BattleScreen from "./screens/BattleScreen/BattleScreen";
import QuestsScreen from "./screens/QuestsScreen/QuestsScreen";
import ArmoryScreen from "./screens/ArmoryScreen/ArmoryScreen";
import ClanScreen from "./screens/ClanScreen/ClanScreen";
import AirdropScreen from "./screens/AirdropScreen/AirdropScreen";
import "../styles/ScreenContainer.css";

function ScreenContainer({
  currentScreen,
  userInfo,
  onBattlePressed,
  onBattleFriendRowPressed,
  onInviteFriendPressed,
  onCopyInviteLinkPressed,
  onNextCharacterPressed,
  onPrevCharacterPressed,
  currentCharacterIndex,
  characterArray,
  onEthAddressSubmit,
  ethereumAddress,
  quests,
  dailyQuests,
  onDailySpoilsPressed,
  onClaimDailySpoils,
  onArmoryItemUpgrade,
  loadingFriendBattleId,
}) {
  switch (currentScreen) {
    case "Battle":
      return (
        <BattleScreen
          userInfo={userInfo}
          onBattlePressed={onBattlePressed}
          onNextCharacterPressed={onNextCharacterPressed}
          onPrevCharacterPressed={onPrevCharacterPressed}
          currentCharacterIndex={currentCharacterIndex}
          characterArray={characterArray}
        />
      );
    case "Armory":
      return (
        <ArmoryScreen userInfo={userInfo} onItemUpgrade={onArmoryItemUpgrade} />
      );
    case "Quests":
      return (
        <QuestsScreen
          userInfo={userInfo}
          quests={quests}
          dailyQuests={dailyQuests}
        />
      );
    case "Clan":
      return (
        <ClanScreen
          userInfo={userInfo}
          onBattleFriendRowPressed={onBattleFriendRowPressed}
          onInviteFriendPressed={onInviteFriendPressed}
          onCopyInviteLinkPressed={onCopyInviteLinkPressed}
          loadingFriendBattleId={loadingFriendBattleId}
        />
      );
    case "Airdrop":
      return (
        <AirdropScreen
          userInfo={userInfo}
          ethereumAddress={ethereumAddress}
          onEthAddressSubmit={onEthAddressSubmit}
        />
      );
    default:
      return (
        <BattleScreen
          userInfo={userInfo}
          onBattlePressed={onBattlePressed}
          onNextCharacterPressed={onNextCharacterPressed}
          onPrevCharacterPressed={onPrevCharacterPressed}
          currentCharacterIndex={currentCharacterIndex}
          characterArray={characterArray}
        />
      );
  }
}

export default ScreenContainer;
