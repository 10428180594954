import React from "react";
import leaderboardContainerRankingLeft from "../../../assets/images/leaderboardContainerRankingLeft.png";
import leaderboardContainerRankingCenter from "../../../assets/images/leaderboardContainerRankingCenter.png";
import leaderboardContainerRankingRight from "../../../assets/images/leaderboardContainerRankingRight.png";
import lootIcon from "../../../assets/images/loot.png";
import eloIcon from "../../../assets/images/ELO.png";
import "../../../styles/LeaderboardRow.css";
import { formatLootNumber } from "../../../utils/Utils";

const LeaderboardRow = ({ rank, name, elo, loot }) => {
  return (
    <div className="leaderboard-row">
      <div className="rank-number">{rank}</div>
      <div className="player-data">
        <div className="row-background">
          <img
            src={leaderboardContainerRankingLeft}
            alt=""
            className="row-bg-left"
          />
          <img
            src={leaderboardContainerRankingCenter}
            alt=""
            className="row-bg-center"
          />
          <img
            src={leaderboardContainerRankingRight}
            alt=""
            className="row-bg-right"
          />
        </div>
        <div className="row-content">
          <div className="player-details">
            <span className="row-name">{name.toLowerCase()}</span>
            <div className="row-elo">
              <img src={eloIcon} alt="ELO" className="row-elo-icon" />
              <span className="row-elo-text">{elo}</span>
            </div>
          </div>
          <div className="row-loot">
            <img src={lootIcon} alt="Loot" className="row-loot-icon" />
            <span className="row-loot-text">{formatLootNumber(loot)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderboardRow;
