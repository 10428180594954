export const getDailySpoilsInfo = (userInfo) => {
  const now = new Date();
  if (!userInfo?.brutalBlocksDSLastClaimDate) {
    return {
      currentDay: 1,
      hasClaimedToday: false,
      isComplete: false,
    };
  }

  const lastClaimDate = new Date(userInfo.brutalBlocksDSLastClaimDate);
  const lastClaimUTCDate = lastClaimDate.toISOString().split("T")[0];
  const todayUTCDate = now.toISOString().split("T")[0];
  const yesterdayUTCDate = new Date(now.getTime() - 86400000)
    .toISOString()
    .split("T")[0];

  const hasClaimedToday = lastClaimUTCDate === todayUTCDate;

  let currentDay = 1;
  if (lastClaimUTCDate === yesterdayUTCDate) {
    currentDay = Math.min((userInfo.brutalBlocksDSClaimStreak || 0) + 1, 10);
  } else if (!hasClaimedToday) {
    currentDay = 1;
  } else {
    currentDay = Math.min(userInfo.brutalBlocksDSClaimStreak || 0, 10);
  }

  return {
    currentDay,
    hasClaimedToday,
    isComplete: hasClaimedToday,
  };
};
