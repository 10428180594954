import React from "react";
import BottomSheet from "../../BottomSheet";
import LeaderboardScreen from "./LeaderboardScreen";
import "../../../styles/LeaderboardBottomSheet.css";
import useWindowSize from "../../../utils/WindowSize";

const LeaderboardBottomSheet = ({
  isOpen,
  onClose,
  userRank,
  userData,
  topPlayers,
}) => {
  const { height } = useWindowSize();
  return (
    <BottomSheet
      isOpen={isOpen}
      onCancel={onClose}
      canTapOutToCancel={true}
      hideButtons={true}
      height={height > 110 ? height - 110 : height}
      altBackground={true}
      longerContent={true}
    >
      <LeaderboardScreen
        userRank={userRank}
        userData={userData}
        topPlayers={topPlayers}
      />
    </BottomSheet>
  );
};

export default LeaderboardBottomSheet;
