interface LeaderboardPlayer {
  userId: string;
  username: string;
  rank: number;
  loot: number;
  elo: number;
}

interface LeaderboardBucket {
  id: string;
  displayName: string;
  min: number;
  max: number | null;
  count: number;
}

export interface LeaderboardResponse {
  leaderboard: LeaderboardPlayer[];
  userRank?: number;
  bucketData: {
    totalPlayers: number,
    buckets: LeaderboardBucket[],
  };
}

function calculateBucketPosition(
  currentLoot: number,
  bucket: {
    min: number,
    max: number | null,
    count: number,
  }
): number {
  if (bucket.max === null) {
    return 0; // User is at start of infinite bucket
  }

  const bucketRange = bucket.max - bucket.min;
  if (bucketRange <= 0) {
    return Math.floor(bucket.count / 2); // Place in middle if range is invalid
  }

  const positionInBucket = (currentLoot - bucket.min) / bucketRange;
  return Math.floor(
    bucket.count * (1 - Math.max(0, Math.min(1, positionInBucket)))
  );
}

export function calculateCurrentRank(
  leaderboardData: LeaderboardResponse,
  currentLoot: number,
  userId: string,
  username: string,
  elo: number
): {
  updatedLeaderboard: LeaderboardPlayer[],
  userRank: number,
} {
  let userRank: number;
  const leaderboard = [...leaderboardData.leaderboard];
  const existingUserIndex = leaderboard.findIndex(
    (player) => player.userId === userId
  );

  // Find where user should be based on current loot
  let insertIndex = leaderboard.findIndex(
    (player) => currentLoot >= player.loot
  );
  if (insertIndex === -1) insertIndex = leaderboard.length;

  // Case 1: User is already in correct position
  if (existingUserIndex === insertIndex && existingUserIndex !== -1) {
    return {
      updatedLeaderboard: leaderboard,
      userRank: insertIndex + 1,
    };
  }

  // Case 2: User's position needs to change or they need to be added/removed
  if (existingUserIndex !== -1) {
    // Remove user from old position
    leaderboard.splice(existingUserIndex, 1);
  }

  // If user should be in top 99 based on their current loot
  if (insertIndex < 99) {
    const updatedPlayer: LeaderboardPlayer = {
      userId,
      username,
      rank: insertIndex + 1,
      loot: currentLoot,
      elo,
    };

    leaderboard.splice(insertIndex, 0, updatedPlayer);
    userRank = insertIndex + 1;

    // Re-calculate ranks for all players after insertion
    for (let i = insertIndex + 1; i < leaderboard.length; i++) {
      leaderboard[i].rank = i + 1;
    }
  } else {
    // User is not in top 99, calculate approximate rank using buckets
    const { buckets } = leaderboardData.bucketData;
    let rank = 0;

    // Find user's bucket
    const userBucket = buckets.find(
      (bucket) =>
        currentLoot >= bucket.min &&
        (bucket.max === null || currentLoot <= bucket.max)
    );

    if (userBucket) {
      // Sum up users in higher buckets
      for (const bucket of buckets) {
        if (bucket.min > currentLoot) {
          rank += bucket.count;
        } else if (bucket.id === userBucket.id) {
          // Linear interpolation within user's bucket
          const position = calculateBucketPosition(currentLoot, userBucket);
          rank += position;
          break;
        }
      }
      userRank = rank + 1;
    } else {
      // Fallback to last known rank if something goes wrong
      userRank =
        leaderboardData.userRank || leaderboardData.bucketData.totalPlayers;
    }
  }

  // Trim to 99 players if needed
  while (leaderboard.length > 99) {
    leaderboard.pop();
  }

  return {
    updatedLeaderboard: leaderboard,
    userRank,
  };
}
