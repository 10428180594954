import React, { useState, useMemo } from "react";
import BottomSheet from "../../BottomSheet";
import DailySpoilsDayItem from "./DailySpoilsDayItem";
import QuestsTitleSeparator from "./QuestsTitleSeparator";
import "../../../styles/DailySpoilsBottomSheet.css";

import dailySpoilsTitle from "../../../assets/images/dailySpoilsTitle.png";
import dailySpoilsClaimButton from "../../../assets/images/dailySpoilsClaimButton.png";
import dailySpoilsClaimButtonPressed from "../../../assets/images/dailySpoilsClaimButtonPressed.png";
import dailySpoilsClaimButtonDisabled from "../../../assets/images/dailySpoilsClaimButtonDisabled.png";
import dailySpoilsClaimButtonLoading from "../../../assets/images/dailySpoilsClaimButtonLoading.png";
import LoadingSpinner from "../../LoadingSpinner";
import { getDailySpoilsInfo } from "../../../utils/DailySpoilsUtils";

const DailySpoilsBottomSheet = ({
  isOpen,
  onCancel,
  userInfo,
  onClaimDailySpoils,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isPressed, setIsPressed] = useState(false);

  const rewards = [
    100000, 250000, 500000, 750000, 1000000, 1250000, 1500000, 1750000, 2000000,
    2500000,
  ];

  const { currentDay, hasClaimedToday } = useMemo(
    () => getDailySpoilsInfo(userInfo),
    [userInfo]
  );

  const getClaimButtonImage = () => {
    if (hasClaimedToday) return dailySpoilsClaimButtonDisabled;
    if (isLoading) return dailySpoilsClaimButtonLoading;
    if (isPressed) return dailySpoilsClaimButtonPressed;
    return dailySpoilsClaimButton;
  };

  const handleClaim = async () => {
    if (hasClaimedToday || isLoading) return;

    setIsLoading(true);
    try {
      await onClaimDailySpoils();
    } catch (error) {
      console.error("Error claiming daily spoils:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMouseDown = () => {
    if (!hasClaimedToday && !isLoading) {
      setIsPressed(true);
    }
  };

  const handleMouseUp = () => {
    setIsPressed(false);
  };

  const claimButtonImage = getClaimButtonImage();

  return (
    <BottomSheet
      isOpen={isOpen}
      onCancel={onCancel}
      canTapOutToCancel={true}
      hideButtons={true}
      height={600}
    >
      <div className="daily-spoils-container">
        <img
          src={dailySpoilsTitle}
          alt="Daily Spoils"
          className="title-image"
        />
        <QuestsTitleSeparator width="90%" />

        <div className="rewards-grid">
          {rewards.map((reward, index) => (
            <DailySpoilsDayItem
              key={index}
              day={index + 1}
              reward={reward}
              isCompleted={
                index + 1 < currentDay ||
                (index + 1 === currentDay && hasClaimedToday)
              }
              isActive={index + 1 === currentDay && !hasClaimedToday}
              isLocked={index + 1 > currentDay}
              isFullWidth={index === 9}
              onClick={
                index + 1 === currentDay && !hasClaimedToday
                  ? handleClaim
                  : undefined
              }
            />
          ))}
        </div>

        <div className="claim-button-container">
          <button
            className={`claim-button ${
              !hasClaimedToday && !isLoading ? "clickable" : ""
            }`}
            onClick={handleClaim}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            onTouchStart={handleMouseDown}
            onTouchEnd={handleMouseUp}
            disabled={hasClaimedToday || isLoading}
          >
            <img
              src={claimButtonImage}
              alt={hasClaimedToday ? "Claim Again Tomorrow" : "Claim"}
              className="claim-button-image"
            />
          </button>
          {isLoading && (
            <LoadingSpinner
              style={{
                position: "absolute",
                marginBottom: 4,
                height: 30,
                width: 30,
              }}
            />
          )}
        </div>
      </div>
    </BottomSheet>
  );
};

export default DailySpoilsBottomSheet;
