import config from "../config";
import { ROUTES } from "../utils/Routes";
import { logToGlobalLogger } from "../utils/GlobalLogger";
import { LeaderboardResponse } from "../utils/LeaderboardUtils";

let accessToken = null;

// Helper function to attempt re-authentication
const attemptReAuthentication = async () => {
  console.warn("Attempting re-authentication");
  if (window.Telegram && window.Telegram.WebApp) {
    const initData = window.Telegram.WebApp.initData;
    if (initData) {
      try {
        await authenticateWithTelegramInitData(initData);
      } catch (authError) {
        console.error("Re-authentication failed:", authError);
        throw new Error("Unauthorized");
      }
    } else {
      console.error("No initData available for re-authentication");
      throw new Error("Unauthorized");
    }
  } else {
    console.error("Telegram WebApp not available for re-authentication");
    throw new Error("Unauthorized");
  }
};

// Utility function for authenticated API calls
const authenticatedFetch = async (url, options = {}) => {
  // Attempt to re-authenticate if accessToken is not available
  if (!accessToken) {
    console.warn("No access token available, attempting re-authentication");
    await attemptReAuthentication();
  }

  const headers = {
    ...options.headers,
    Authorization: `Bearer ${accessToken}`,
  };

  try {
    let response = await fetch(url, { ...options, headers });

    if (response.status === 401) {
      // Token might be expired or invalid
      console.warn(
        "Access token expired or invalid, attempting re-authentication"
      );
      await attemptReAuthentication();
      // Retry the request with the new access token
      headers.Authorization = `Bearer ${accessToken}`;
      response = await fetch(url, { ...options, headers });

      if (response.status === 401) {
        // If it still fails, throw an error
        console.error("Re-authentication failed, unauthorized");
        throw new Error("Unauthorized");
      }
    }

    return response;
  } catch (error) {
    console.error("Error in authenticatedFetch:", error);
    throw error;
  }
};

// Authentication function using Telegram initData
export const authenticateWithTelegramInitData = async (initData) => {
  logToGlobalLogger(
    "apiService.js/authenticateWithTelegramInitData: config.apiUrl: ",
    config.apiUrl
  );
  console.log("environment: ", process.env.REACT_APP_ENV);
  try {
    const url = `${config.apiUrl}${ROUTES.AUTH}`;
    logToGlobalLogger(
      "apiService.js/authenticateWithTelegramInitData: Attempting to authenticate with URL:",
      url
    );

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ initData }),
    });

    logToGlobalLogger(
      "apiService.js/authenticateWithTelegramInitData: Response status:",
      response.status
    );
    logToGlobalLogger(
      "apiService.js/authenticateWithTelegramInitData: Response headers:",
      JSON.stringify(Array.from(response.headers.entries()))
    );

    if (!response.ok) {
      const errorText = await response.text();
      logToGlobalLogger("Error response body:", errorText);
      throw new Error(
        `Authentication failed: ${response.status} ${response.statusText}`
      );
    }

    const data = await response.json();
    logToGlobalLogger(
      "apiService.js/authenticateWithTelegramInitData: Response Data: ",
      JSON.stringify(data, null, 2)
    );

    // Assuming the backend returns an accessToken
    accessToken = data.accessToken;

    return data;
  } catch (error) {
    logToGlobalLogger(
      "apiService.js/authenticateWithTelegramInitData: Authentication error:",
      error.message
    );
    logToGlobalLogger(
      "apiService.js/authenticateWithTelegramInitData: Error stack:",
      error.stack
    );
    throw error;
  }
};

// API Wrappers
export const fetchUserInfo = async () => {
  try {
    const response = await authenticatedFetch(`${config.apiUrl}${ROUTES.USER}`);

    if (!response.ok) {
      throw new Error("Failed to fetch user info");
    }

    const data = await response.json();
    if (!data.user) {
      throw new Error("User data not found in response");
    }
    return data.user;
  } catch (error) {
    console.error("Error fetching user info:", error);
    throw error;
  }
};

export const fetchUserFriends = async () => {
  try {
    const response = await authenticatedFetch(
      `${config.apiUrl}${ROUTES.USER_FRIENDS}`
    );

    if (!response.ok) {
      throw new Error("Failed to fetch user friends");
    }

    const data = await response.json();

    if (!data.friends || !Array.isArray(data.friends)) {
      throw new Error("Invalid response format for user friends");
    }

    return data.friends.map((friend) => ({
      userId: friend.userId,
      username: friend.username,
      brutalBlocksLoot: friend.brutalBlocksLoot || 0,
      brutalBlocksElo: friend.brutalBlocksElo || 600,
    }));
  } catch (error) {
    console.error("Error fetching user friends info:", error);
    throw error;
  }
};

export const claimDailySpoils = async () => {
  try {
    const response = await authenticatedFetch(
      `${config.apiUrl}${ROUTES.CLAIM_DAILY_SPOILS}`,
      {
        method: "POST",
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Failed to claim Daily Spoils");
    }

    const data: ClaimDailySpoilsResponse = await response.json();

    // Validate that the required fields are present
    if (
      !(
        "claimStreak" in data &&
        "newLastClaimDate" in data &&
        "newTotalLoot" in data
      )
    ) {
      throw new Error("Invalid response from server");
    }

    return {
      claimStreak: data.claimStreak,
      newLastClaimDate: data.newLastClaimDate,
      newTotalLoot: data.newTotalLoot,
    };
  } catch (error) {
    console.error("Error claiming Daily Spoils:", error);
    throw error;
  }
};

export const updateUserData = async (updates) => {
  try {
    const response = await authenticatedFetch(
      `${config.apiUrl}${ROUTES.USER}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updates),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update user data");
    }

    const data = await response.json();
    console.log("User data updated:", data.user);
    return data.user;
  } catch (error) {
    console.error("Error updating user data:", error);
    throw error;
  }
};

export const getFriendRoomId = async (
  friendId: string
): Promise<{ roomId: string, status: string }> => {
  try {
    const response = await authenticatedFetch(
      `${config.apiUrl}${ROUTES.FRIEND_BATTLE_ROOM}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ friendId }),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Failed to initiate friend battle");
    }

    const data = await response.json();

    // Validate that required fields are present
    if (!("roomId" in data && "status" in data)) {
      throw new Error("Invalid response from server");
    }

    return {
      roomId: data.roomId,
      status: data.status,
    };
  } catch (error) {
    console.error("Error initiating friend battle:", error);
    throw error;
  }
};

export const upgradeArmoryItem = async (itemId: string) => {
  try {
    const response = await authenticatedFetch(
      `${config.apiUrl}${ROUTES.UPGRADE_ARMORY_ITEM}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ itemId }),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Failed to upgrade item");
    }

    const data = await response.json();
    return data.user;
  } catch (error) {
    console.error("Error upgrading armory item:", error);
    throw error;
  }
};

export const fetchLeaderboard = async (): Promise<LeaderboardResponse> => {
  try {
    const response = await authenticatedFetch(
      `${config.apiUrl}${ROUTES.LEADERBOARD}`
    );

    if (!response.ok) {
      throw new Error("Failed to fetch leaderboard data");
    }

    const data: LeaderboardResponse = await response.json();

    // Validate the response structure
    if (!data.leaderboard || !Array.isArray(data.leaderboard)) {
      throw new Error("Invalid leaderboard data format");
    }

    if (!data.bucketData || !Array.isArray(data.bucketData.buckets)) {
      throw new Error("Invalid bucket data format");
    }

    // Return the validated data
    return {
      leaderboard: data.leaderboard,
      userRank: data.userRank,
      bucketData: {
        totalPlayers: data.bucketData.totalPlayers,
        buckets: data.bucketData.buckets,
      },
    };
  } catch (error) {
    console.error("Error fetching leaderboard:", error);
    throw error;
  }
};
