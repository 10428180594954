import React from "react";
import LeaderboardRow from "./LeaderboardRow";
import leaderboardHeader from "../../../assets/images/leaderboardHeader.png";
import leaderboardPlayerRankingLeft from "../../../assets/images/leaderboardPlayerRankingLeft.png";
import leaderboardPlayerRankingCenter from "../../../assets/images/leaderboardPlayerRankingCenter.png";
import leaderboardPlayerRankingRight from "../../../assets/images/leaderboardPlayerRankingRight.png";
import lootIcon from "../../../assets/images/loot.png";
import eloIcon from "../../../assets/images/ELO.png";
import { formatLootNumber } from "../../../utils/Utils";
import "../../../styles/LeaderboardScreen.css";

const LeaderboardScreen = ({ userRank, userData, topPlayers }) => {
  let playerNameFontSize = 18;
  if (userData.name.length > 10) {
    playerNameFontSize = 15;
  } else if (userData.name.length > 9) {
    playerNameFontSize = 17;
  }

  const rankString = userRank.toString();
  let userRankFontSize = 22;
  if (rankString.length > 3) {
    userRankFontSize = 12;
  } else if (rankString.length > 3) {
    userRankFontSize = 14;
  } else if (rankString.length > 2) {
    userRankFontSize = 16;
  } else if (rankString.length > 1) {
    userRankFontSize = 18;
  }
  return (
    <div className="leaderboard-screen">
      <img
        src={leaderboardHeader}
        alt="Leaderboard"
        className="leaderboard-header"
      />

      <div className="user-ranking-container">
        <div className="ranking-background">
          <img src={leaderboardPlayerRankingLeft} alt="" className="bg-left" />
          <img
            src={leaderboardPlayerRankingCenter}
            alt=""
            className="bg-center"
          />
          <img
            src={leaderboardPlayerRankingRight}
            alt=""
            className="bg-right"
          />
        </div>

        <div className="ranking-content">
          <div className="rank-section" style={{ fontSize: userRankFontSize }}>
            #{rankString}
          </div>
          <div className="player-section">
            <div
              className="leaderboard-player-name"
              style={{ fontSize: playerNameFontSize }}
            >
              {userData.name.toLowerCase()}
            </div>
            <div className="stats-container-elo">
              <img src={eloIcon} alt="ELO" className="elo-icon" />
              <span className="stats-value-elo">{userData.elo}</span>
            </div>
          </div>
          <div className="loot-section">
            <img src={lootIcon} alt="Loot" className="leaderboard-loot-icon" />
            <span className="stats-value">
              {formatLootNumber(userData.loot)}
            </span>
          </div>
        </div>
      </div>

      <div className="leaderboard-list">
        {topPlayers.map((player, index) => (
          <LeaderboardRow
            key={player.id}
            rank={index + 1}
            name={player.name}
            elo={player.elo}
            loot={player.loot}
          />
        ))}
      </div>
    </div>
  );
};

export default LeaderboardScreen;
