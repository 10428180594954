import React from "react";
import "../styles/LoadingSpinner.css";
import loadingSpinner from "../assets/images/loadingSpinner.svg"; // Assuming we use SVG for the spinner

const LoadingSpinner = ({ style }) => {
  return (
    <img
      src={loadingSpinner}
      alt="Loading"
      className="loading-spinner"
      style={style}
    />
  );
};

export default LoadingSpinner;
